// import { StrictMode } from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import GoogleSheetsProvider from 'react-db-google-sheets'

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  styled,
  useTheme,
} from '@mui/material/styles'
import { amber, deepOrange, grey } from '@mui/material/colors'

import CssBaseline from '@mui/material/CssBaseline'

import CustomLoadingComponent from './comps/assets/Loading'
import App from './App'

const drawerWidth = 288
const mainColor = '#c27766'

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: mainColor,
      }),
    },
    secondary: {
      main: '#392219',
    },
    // ...(mode === 'dark' && {
    //   background: {
    //     default: grey[900],
    //     paper: grey[800],
    //   },
    // }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
  typography: {
    // fontFamily: 'OpenSans, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeightLight: 100,
    fontWeightRegular: 300,
    h1: {
      // fontFamily: 'Domine',
    },
    h2: {
      // fontFamily: 'Domine',
    },
    h3: {
      fontFamily: 'Domine',
    },
    h4: {
      fontFamily: 'Domine',
    },
    h5: {
      fontFamily: 'Domine',
    },
  },
})

let selectModeTheme = createTheme(getDesignTokens('light'))
selectModeTheme = responsiveFontSizes(selectModeTheme)

const config = {
  dataLoading: {
    component: CustomLoadingComponent,
  },
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <ThemeProvider theme={selectModeTheme}>
    <GoogleSheetsProvider config={config}>
      {/* <CssBaseline /> */}
      <App />
      {/* <CustomLoadingComponent /> */}
    </GoogleSheetsProvider>
  </ThemeProvider>,
)
