import { Fragment, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'
import { Image } from 'mui-image'

import { alpha, darken, styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import DownloadPDF from '@mui/icons-material/PictureAsPdf'

import moment from '../helpers/moment'

import ReactMarkdown from 'react-markdown'
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'

import Loading from '../comps/assets/Loading'
import ScrollToTop from '../utils/ScrollToTop'
import AppBar from '../comps/navs/AppBar'

export default withGoogleSheets()(function DetailsPage({
  db,
  parent,
  parentTitle,
  data,
}) {
  let { slug } = useParams()
  const theme = useTheme()
  const mqBelow = (breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint))

  const languages = db.config[3]

  // const article = data.content.filter((article) => article.slug === slug)[0]
  const article = db[parent].filter((article) => article.slug === slug)[0]
  // console.log(article)

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `TTT :: ${parentTitle} :: ${article.title}`
  })

  return (
    <Container
      maxWidth="md"
      sx={{ mb: 6, pt: 10 }}
      className={`tttArticle cat-${parent}`}
    >
      <ScrollToTop />
      <AppBar
        parent={parent}
        parentTitle={parentTitle}
        languages={languages}
        showParent
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {article.title && (
            <Typography variant="h3" component="h1" gutterBottom>
              {article.title}
            </Typography>
          )}
          {article.author && (
            <Typography
              // gutterBottom
              variant="caption"
              component="div"
              className="author"
              sx={{
                paddingLeft: theme.spacing(1.5),
                borderLeft: `4px solid`,
              }}
            >
              {/* <strong>{article.author}</strong>,{" "} */}
              {parent === 'biblioteca' && <strong>Data apariției: </strong>}
              {article.datePublished ? (
                <>
                  {/* , {mqBelow('sm') && <br />} */}
                  {moment(article.datePublished).format(
                    'dddd, DD MMMM YYYY, HH:mm',
                  )}
                </>
              ) : (
                article.dateOriginal && (
                  <>
                    {/* , {mqBelow('sm') && <br />} */}
                    {moment(article.dateOriginal).format('dddd, DD MMMM YYYY')}
                  </>
                )
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          {article.hero ? (
            <figure>
              <Image
                src={`https://ttt.photo/images/${parent}/${article.hero}`}
                alt={article.title}
                showLoading={<Loading width="100%" height="100%" />}
                // showLoading={true}
                distance={300}
                duration={500}
              />
              {article.heroCaption && (
                <figcaption>{article.heroCaption}</figcaption>
              )}
            </figure>
          ) : (
            article.cover && (
              <>
                <figure>
                  <Image
                    src={`https://ttt.photo/images/${parent}/${article.cover}`}
                    alt={article.title}
                    showLoading={<Loading width="100%" height="100%" />}
                    // showLoading={true}
                    distance={300}
                    duration={500}
                  />
                  {article.title && <figcaption>{article.title}</figcaption>}
                </figure>

                {parent === 'biblioteca' && (
                  <Box
                    sx={{
                      mt: 2,
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      href={`https://ttt.photo/biblioteca/${article.file}`}
                      target="_blank"
                    >
                      <DownloadPDF sx={{ mr: 1 }} /> DOWNLOAD{' '}
                      {article.file.split('.').pop()}
                    </Button>
                  </Box>
                )}
              </>
            )
          )}
        </Grid>
        <Grid item xs={12} lg={4} sx={mqBelow('lg') ? {} : { zIndex: 1 }}>
          {article.desc && (
            <Box
              sx={{
                mt: mqBelow('lg') ? 0 : -2,
                ml: mqBelow('lg') ? 0 : -10,
                mb: 2,
                px: mqBelow('lg') ? 0 : 3,
                py: mqBelow('lg') ? 0 : 2,
                backgroundColor: mqBelow('lg')
                  ? 'transparent'
                  : darken(theme.palette.background.default, 0.25),
              }}
            >
              {article.desc === '**NEEDS intro text**' ? (
                <Typography
                  component="div"
                  variant="subtitle1"
                  style={{
                    color: 'red',
                    fontWeight: 700,
                  }}
                >
                  <ReactMarkdown
                    children={article.desc}
                    remarkPlugins={[remarkRehype, remarkGfm]}
                  />
                </Typography>
              ) : (
                <Typography
                  component="div"
                  variant="subtitle1"
                  align={mqBelow('lg') ? 'justify' : 'left'}
                  style={{
                    color: 'var(--color-light)',
                    // fontStyle: 'italic',
                    // fontWeight: 400,
                    fontSize: '1rem',
                  }}
                >
                  <ReactMarkdown
                    children={article.desc}
                    remarkPlugins={[remarkRehype, remarkGfm]}
                  />
                </Typography>
              )}
            </Box>
          )}

          {article.text && parent === 'biblioteca' && (
            <Typography
              component="div"
              // variant="subtitle1"
              align={mqBelow('lg') ? 'justify' : 'left'}
              style={{
                // color: 'var(--color-light)',
                // fontStyle: 'italic',
                // fontWeight: 400,
                fontSize: '1rem',
              }}
            >
              <ReactMarkdown
                children={article.text}
                remarkPlugins={[remarkRehype, remarkGfm]}
              />
            </Typography>
          )}
        </Grid>

        {article.text && parent !== 'biblioteca' && (
          <Grid item xs={12}>
            <ReactMarkdown
              children={article.text}
              remarkPlugins={[remarkRehype, remarkGfm]}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {article.tags && (
            <Fragment>
              <Divider />
              <Stack
                direction={mqBelow('sm') ? 'column' : 'row'}
                spacing={mqBelow('sm') ? 2 : 1}
                sx={{ my: mqBelow('sm') ? 2 : 1 }}
              >
                <Typography variant="caption" component="span">
                  Teme:{' '}
                </Typography>
                {article.tags.split(',').map((item, index) => (
                  <Chip
                    size={
                      // mqBelow('sm') ? 'large' :
                      'small'
                    }
                    label={item}
                    key={item + index}
                  />
                ))}
              </Stack>
            </Fragment>
          )}

          {article.dateOriginal && article.sourceOriginal && (
            <Fragment>
              <Divider />
              <Typography variant="caption" component="p" sx={{ mt: 2 }}>
                {article.sourceOriginal ? (
                  <Button
                    color="inherit"
                    size="small"
                    disableRipple
                    href={article.sourceOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Articolul original
                  </Button>
                ) : (
                  'Articolul original'
                )}
                {', '}
                publicat{' '}
                {moment(article.dateOriginal).format(
                  'dddd, DD MMMM YYYY, HH:mm',
                )}
              </Typography>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Container>
  )
})
