import * as React from 'react'
import { Link } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Fab from '@mui/material/Fab'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import MoreIcon from '@mui/icons-material/MoreVert'

import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -20,
  left: 0,
  right: 0,
  margin: '0 auto',
  backgroundColor: '#121212',
})

export default function BottomAppBar({ menu, languages }) {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: '#000',
          backgroundImage: 'none',
        }}
      >
        <Toolbar>
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            aria-label="open mobile menu"
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <StyledFab
            color="secondary"
            aria-label="add"
            component={Link}
            to="/"
            // style={{
            //   textAlign: `${!open ? 'left' : 'center'}`,
            //   textDecoration: 'none',
            // }}
          >
            <img
              src={`https://ttt.photo/images/ttt-Logo-220221${
                theme.palette.mode === 'dark' ? '-white' : ''
              }.png`}
              alt="Transylvania Time Travel"
              className="mobileLogo"
            />
          </StyledFab>
        </Toolbar>
      </AppBar>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        {menu.map((item, index) => {
          if (item.type === 'divider') {
            // console.log(item.id + ' is divider')
            return <Divider key={`divider-${item.id}`} />
          } else {
            // console.log(item.id + ' NOT divider')
            return (
              <ListItem
                button
                component={Link}
                to={`/${typeof item.slug === 'undefined' ? '' : item.slug}`}
                onClick={() => handleClose()}
                key={`mobileMenu-${item.slug}`}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon
                  // sx={{ color: '#392219' }}
                  >
                    {item.icon}
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            )
          }
        })}
        <Divider />
        <ListItem>
          <Box
            pt={1}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {languages[3].value.split(',').map((item) => (
              <span
                className={`fi fi-${item} fis`}
                key={`language-${item}`}
              ></span>
            ))}
          </Box>
        </ListItem>
      </Menu>
    </React.Fragment>
  )
}
