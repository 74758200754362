const Logo = (props) => {
  const { variant, color } = props
  const Graphics = () => {
    switch (variant) {
      case 'loader':
        return (
          <>
            <g id="LMiddle" clipPath="url(#LMask)" fill={color}>
              <path
                id="LLeft"
                d="M119.2,79
        c1.1-0.8-93.3-0.2-93-0.7l-0.9,24.9c1.1-0.8,43.7-1.2,44-1.7c1.3,1.2,1.2,111.3,0.8,110.2l22.4,13.4c1.3,1.2,1.2-122.9,0.8-123.9
        c0.8,0.5,24.2,0.3,24.3,1.9L119.2,79z"
              />
              <path
                id="LCenter"
                d="
        M139.7,230.8c0.8,0.5,1-129.2,1.1-127.6c-1.9-0.3,32.8-1.7,33.4,0l1.4-24.2c1.1-0.8-92.8-0.4-93.8,0l1.3,22.3
        c0.8,0.5,34.4,0.3,34.5,1.9c-1.9-0.3-2,127.8-1.4,129.5C120.9,234,137.9,233,139.7,230.8z"
              />
              <path
                id="LRight"
                d="
        M187.2,213.9c-1.9-0.3-2-112.4-1.4-110.7c1.1-0.8,42.3-1.2,42.5-1.7l0.2-21.5c1.1-0.8-89.6-1.2-89.3-1.7l1.6,24.9
        c-1.9-0.3,21.2-1.7,21.8,0c1.1-0.8-0.8,122.6-0.5,122.1L187.2,213.9z"
              />
            </g>
            <path
              id="LCircle"
              d="M128,8C61.7,8,8,61.7,8,128s53.7,120,120,120c66.3,0,120-53.7,120-120S194.3,8,128,8z M128,225
  c-53.6,0-97-43.4-97-97c0-53.6,43.4-97,97-97c53.6,0,97,43.4,97,97C225,181.6,181.6,225,128,225z"
              fill={color}
            />
          </>
        )
      // break;
      default:
        return (
          <path
            d="M132.2,12.8c-0.9,0-1.9,0-2.8,0c0,0,0,0-0.1,0c0,0,0,0,0,0l-0.5,0c-32.3,0-62.2,12.2-84.1,34.3
          c-21.4,21.6-33,50.6-32.7,81.6c0.5,30.6,12.5,59.3,33.8,80.7c21.5,21.7,50.1,33.6,80.7,33.6c0.3,0,0.6,0,0.9,0c0,0,0,0,0,0
          c0,0,0,0,0.1,0c0.7,0,1.5,0,2.2,0c30.7,0,59.5-11.9,81-33.6c21.8-21.9,33.7-51.3,33.5-82.7c0,0,0,0,0-0.1
          c0.4-30.4-11.2-59.1-32.6-80.8C190.4,24.6,162.2,12.8,132.2,12.8z M73.3,186.8c-0.1,16.3-0.2,16.5-0.4,17.1
          c-0.5,1.1-1.6,1.9-2.8,1.9c-0.7,0-1.4-0.3-1.9-0.7c-23.6-18.3-37.6-46.7-37.6-76.1c-1.8-9.1-0.9-19.7,2.4-27
          c0.2-0.5,0.5-0.9,0.9-1.2c1.1-0.8,1.4-1,21.9-1.7c5.3-0.2,11.3-0.3,12.6-0.5c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4-0.1,0.5-0.1
          c0.7,0,1.5,0.3,2,0.8c1.2,1.1,1.4,1.3,1.7,18.3c0.2,10.9,0.3,24.8,0.3,34.6C73.4,164.1,73.4,176.6,73.3,186.8z M120.2,104.6
          c-1.4,8.5-1.7,112.3-1.1,118.6c0.2,0.9-0.1,1.9-0.7,2.6c-0.6,0.6-1.4,1-2.2,1c-0.3,0-0.5,0-0.8-0.1l-1.8-0.5
          c-7-1.8-15.7-4.1-22.4-7.3c-0.3-0.1-0.5-0.3-0.8-0.5c-0.9-0.9-1.2-2.2-0.7-3.4c0.9-8,1.1-107.5,0.7-113.3c-0.2-1,0.2-2.1,1-2.7
          c0.6-0.5,1.3-0.7,1.9-0.7c0.3,0,0.5,0,0.8,0.1c0.8,0.1,3.7,0.2,6.3,0.3c16.9,0.5,20,0.7,20.2,4.4
          C120.6,103.6,120.5,104.1,120.2,104.6z M165.9,134.2c-0.1,17.4-0.4,38.4-0.6,55.4c-0.2,13.3-0.3,24.9-0.3,26.4
          c0.1,0.5,0,1.1-0.3,1.6c-0.2,0.5-0.6,0.9-1,1.2l0,0c-5.9,4-13.9,6.1-23.7,6.1l-0.3,0c0,0-0.1,0-0.1,0c-0.5,0-1.1-0.1-1.5-0.4
          c-1.2-0.7-1.7-2.1-1.4-3.4c0,0,0,0,0,0c0.5-5.6,0.8-72.5,0.9-101.3c0-9.9,0.1-14.2,0.1-15.8c-0.1-0.3-0.1-0.7-0.1-1
          c0.2-2.8,2.1-2.9,7.8-3.2c3.4-0.2,7-0.3,9.9-0.3c5.8,0,8.5,0.3,9.7,2C166,102.5,166.1,104.6,165.9,134.2z M219.6,98.6
          c0,0,0.1,0,0.1,0c1.2,0.1,2.2,0.8,2.7,1.9c3.5,9.2,5,17.6,4.5,26.4c0.7,30.8-12.7,59.4-37.9,80.4c-0.5,0.5-1.2,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.4,0c-3-0.4-3.2-1.4-3.6-17.8c-0.2-8.2-0.4-19.7-0.5-33.2c-0.2-23.7-0.1-49.6,0.2-53.5c0.1-1.7,0.9-2.5,1.7-2.9
          c1.1-0.6,3.6-0.8,21.6-1.4c5.3-0.2,11.3-0.3,12.7-0.5C219.1,98.6,219.3,98.6,219.6,98.6z M213.2,79.6c0.1,1.1-0.3,2.2-1.2,2.8
          c-0.5,0.4-1.2,0.6-1.8,0.6c-0.2,0-0.4,0-0.6-0.1c-0.1,0-0.1,0-0.2,0c-4.9-0.4-56.5-0.7-98.1-1c-17.6-0.1-34.2-0.2-46.4-0.3
          c-6.5-0.1-11.3-0.1-14.6-0.1c-7.1-0.1-7.1-0.1-8-1.6c-0.5-0.9-0.6-2-0.1-2.9c0.1-0.1,0.1-0.2,0.2-0.3c19.3-28.8,51.1-46.8,82.8-46.8
          c1.3,0,2.7,0,4,0.1c0.2,0,0.5,0,0.7,0c34.1,0,65,18.1,82.7,48.3C213,78.7,213.2,79.1,213.2,79.6z"
            fill={color}
          />
        )
    }
  }
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 256 256',
      }}
      id="LTTT"
      x={0}
      y={0}
      {...props}
    >
      <defs>
        <linearGradient
          id="nannGrad"
          gradientUnits="userSpaceOnUse"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          gradientTransform="rotate(57)"
        >
          <stop offset="23%" stopColor="#efb89e" stopOpacity="1" />
          <stop offset="100%" stopColor="#986b4f" stopOpacity="1" />
        </linearGradient>
      </defs>
      <clipPath id="LMask">
        <circle id="LMaskCircle" cx={128} cy={128} r={120} />
      </clipPath>
      <Graphics />
    </svg>
  )
}

export default Logo
