import { createContext, useContext } from 'react'
import { Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import Icon from '@mui/material/Icon'
import SearchIcon from '@mui/icons-material/Search'

import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

import Logo from '../assets/Logo'
import ColorModeToggler from '../../helpers/ColorModeToggler'

export default function PersistentDrawerRight({
  drawerWidth,
  open,
  menu,
  languages,
  // handleDrawerOpen,
  handleDrawerClose,
  context,
}) {
  const theme = useTheme()
  const colorMode = useContext(context)

  // console.log(languages[3].value.split(','))

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={open}
      // PaperProps={
      //   {
      //     // sx: {
      //     //   // backgroundColor: '#f5f4f2',
      //     //   backgroundColor: theme.palette.primary.main,
      //     // },
      //   }
      // }
      className={`drawerMenu${open ? ' drawerMenuOpened' : ''}`}
      sx={{
        // width: drawerWidth,
        width: 58,
        // minHeight: open ? 'calc(100vh + 16px)' : '100vh',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'space-between',
          width: drawerWidth,
          // minHeight: '100vh',
          // minHeight: open ? 'calc(100vh + 16px)' : '100vh',
          transform: open
            ? 'none'
            : `translateX(${drawerWidth - 58}px) !important`,
          visibility: 'visible !important',
          // backgroundColor: theme.palette.primary.main,
          // backgroundColor: '#f5f4f2',

          '& .drawerCopyright': {
            // transform: open
            //   ? 'none'
            //   : `translateX(${drawerWidth - 58}px) !important`,
            display: open ? 'block' : 'none',
          },
        },
      }}
    >
      <Box
        px={2}
        py={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ flexGrow: 1 }}
      >
        <Link
          to="/"
          style={{
            textAlign: `${!open ? 'left' : 'center'}`,
            textDecoration: 'none',
          }}
        >
          <Logo
            alt="ttt"
            className={`drawerLogo${!open ? ' drawerLogoMinified' : ''} ${
              theme.palette.mode === 'dark' ? 'dark' : 'white'
            }`}
          />
          <Typography
            variant="caption"
            component="div"
            noWrap
            className="drawerLogoText"
            // sx={{ color: '#392219' }}
            sx={{ color: 'white' }}
          >
            Transylvania Time Travel
          </Typography>
        </Link>
        <Box mt={3} textAlign="center">
          {languages[3].value.split(',').map((item) => (
            <span
              className={`fi fi-${item} fis`}
              key={`language-${item}`}
            ></span>
          ))}
          <Typography
            variant="caption"
            component="div"
            noWrap
            className="drawerLogoText"
            // sx={{ color: '#392219' }}
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.35)' }}
          >
            soon
          </Typography>
        </Box>
      </Box>
      <Box
        className="drawerLinks"
        style={{
          transition: 'all 0.25s ease-in-out',
          // transform: open ? 'translateY(0px)' : 'translateY(58px)',
        }}
      >
        <List style={{ paddingBottom: open ? '8px' : 0 }}>
          {/* <ListItem button component={Link} to={`/nested`}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Icon sx={{ color: '#392219' }}>layers</Icon>
            </ListItemIcon>
            <ListItemText
              primary={`Nested Home`}
              secondary="react-router :: test page"
            />
          </ListItem> */}
          {open && (
            <ListItem sx={{ justifyContent: 'flex-end' }}>
              <TextField
                id="outlined-basic"
                label="Caută"
                variant="outlined"
                size="small"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#555' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          )}
          {/* <Divider />
          <ListItem>
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </ListItem> */}
          {/* <Divider />
          <ColorModeToggler context={context} /> */}
          <Divider />
          {menu.map((item, index) => {
            if (item.type === 'divider') {
              // console.log(item.id + ' is divider')
              return <Divider key={`divider-${item.id}`} />
            } else {
              // console.log(item.id + ' NOT divider')
              // console.log(typeof item.slug)
              return (
                <ListItem
                  button
                  component={Link}
                  to={`/${typeof item.slug === 'undefined' ? '' : item.slug}`}
                  onClick={() => handleDrawerClose()}
                  key={`drawerMenu-${item.slug}`}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <Icon
                    // sx={{ color: '#392219' }}
                    >
                      {item.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              )
            }
          })}
        </List>
        {/* <Divider /> */}
        {/* <Box px={2} pt={2} pb={2.5} className="drawerCopyright"> */}
        {/* <Box
          mb={2}
          // textAlign="center"
        >
          <Button
            // variant="contained"
            size="small"
            // class="social-media-link-nav ig"
            sx={{ textTransform: 'none', padding: theme.spacing(1) }}
            href="https://www.instagram.com/"
            target="_blank"
            rel="noreferrer"
          >
            ig
          </Button>
        </Box> */}
        {/* <Box>
            <Typography variant="caption" component="div" align="center">
              &copy; {new Date().getFullYear()} Transilvania Time Travel
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Drawer>
  )
}
