import * as React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ParentIcon from '@mui/icons-material/ArrowCircleUp'
import BackIcon from '@mui/icons-material/ArrowCircleLeft'

function ReactOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 56,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    className: trigger ? 'AppBarBkOn' : 'AppBarContent',
  })
}

export default function ButtonAppBar(props) {
  const { parent, parentTitle, showParent, languages } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const mqBelow = (breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint))

  // console.log(languages.value)
  console.log(parent)

  return (
    <ReactOnScroll {...props}>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        component="div"
        sx={{
          pr: mqBelow('sm') ? 0 : 6,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
        // style={{ flexDirection: 'row' }}
        >
          <IconButton
            color="inherit"
            size="small"
            onClick={() => navigate(-1)}
            // className="btn btn-secondary btn-sm"
            title={`Înapoi`}
          >
            <BackIcon />
            {/* Înapoi */}
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            // onClick={() => navigate(-1)}
            component={Link}
            to={`/${parent}`}
            // className="btn btn-secondary btn-sm"
            title={parentTitle}
          >
            <ParentIcon />
          </IconButton>
          {/* {showParent && (
            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
              <Typography variant="caption" component={Link} to={`/${parent}`}>
                {parentTitle}
              </Typography>
            </Box>
          )} */}
        </Toolbar>
        <Box
          mr={3}
          style={{
            display: 'flex',
            alignSelf: 'center',
            width: 'auto',
            verticalAlign: 'text-top',
          }}
        >
          {languages.value.split(',').map((item) => (
            <span
              className={`fi fi-${item} fis`}
              key={`language-${item}`}
            ></span>
          ))}
        </Box>
      </AppBar>
    </ReactOnScroll>
  )
}
