import React from 'react'
// import PropTypes from 'prop-types'

// import ReactMarkdown from 'react-markdown'
import { withGoogleSheets } from 'react-db-google-sheets'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import components from '../components'
import Section from './Section'

// const useStyles = makeStyles((theme) => ({
//   organizationsContainer: {
//     justifyContent: 'center',
//     marginTop: theme.spacing(2),
//   },
// }))

function Organizations(props) {
  // const classes = useStyles()
  const { title, dataSource, db, spacing } = props
  const data = db[dataSource]

  return (
    <Section component="section" className="sponsors">
      <Container maxWidth={false}>
        <Grid
          container
          spacing={spacing}
          // className={classes.organizationsContainer}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4" component="h2" gutterBottom align="center">
              {title}
            </Typography>
          </Grid>
          {dataSource &&
            data.map((data, index) => {
              // console.log(data)
              const RenderComponent = components[data.type]
              if (JSON.parse(data.published.toLowerCase())) {
                return (
                  <Grid
                    item
                    {...JSON.parse(data.props).grid}
                    display="flex"
                    justifyContent="center"
                    key={index}
                  >
                    <RenderComponent
                      dataSource={data}
                      imageFolder={dataSource}
                    />
                  </Grid>
                )
              }
              return null
            })}
        </Grid>
      </Container>
    </Section>
  )
}

Organizations.propTypes = {}

export default withGoogleSheets()(Organizations)
