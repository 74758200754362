import * as React from 'react'
import { Link } from 'react-router-dom'

import { Image } from 'mui-image'

import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

import Icon from '@mui/material/Icon'

import { useTheme } from '@mui/material/styles'

import moment from '../../helpers/moment'

export default function ActionAreaCard({
  content,
  to,
  shadow,
  openDetails,
  catIcon,
  parent,
}) {
  const theme = useTheme()
  const cardSize = content.cardSize || 1

  // console.log(content)

  return (
    <Card
      elevation={shadow}
      sx={{
        width: '100%',
        // minWidth: `${cardSize * 320 + 30 * cardSize}px`,
        borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      }}
      onClick={() => openDetails(content)}
    >
      <CardActionArea
      // component={Link} to={to}
      >
        <CardMedia
          component="img"
          // height="140"
          image={`https://ttt.photo/images/${parent}/${content.Media}`}
          alt={content.Headline}
          // sx={{
          //   minHeight: `${cardSize * 448 + 30 * (cardSize - 1)}px`,
          // }}
        />
        {/* <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '6px 8px',
            backgroundColor: `var(--color-dark-${content.category.substring(
              0,
              3,
            )})`,
          }}
        >
          {content.Headline}
          <Icon style={{ verticalAlign: 'middle', fontSize: '15px' }}>
            {catIcon}
          </Icon>
        </CardContent> */}
      </CardActionArea>
    </Card>
  )
}
