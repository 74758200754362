import { Fragment, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'
import { Image } from 'mui-image'

import Timeline from 'timelinejs-react'

import { alpha, darken, styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import moment from '../helpers/moment'

import ReactMarkdown from 'react-markdown'
import remarkRehype from 'remark-rehype'
import remarkGfm from 'remark-gfm'

import Loading from '../comps/assets/Loading'
import ScrollToTop from '../utils/ScrollToTop'
import AppBar from '../comps/navs/AppBar'

export default withGoogleSheets()(function TimelinePage({
  db,
  parent,
  parentTitle,
  title,
  data,
}) {
  let { slug } = useParams()
  const theme = useTheme()
  const mqBelow = (breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint))

  const languages = db.config[3]

  const TLevents = db.timelineTMP
  const TLtitle: TitleSlide = {}
  const TLoptions: TimelineOptions = {
    start_at_end: true,
  }

  // const TimelineComponent: React.FC = () => {
  //     return <Timeline
  //         target={<div className="timeline"/>}
  //         events={events}
  //         title={title} // optional
  //         options={options} // optional
  //     />;
  // };

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `TTT :: ${title}`
  })

  // console.log(db.timelineTMP)

  return (
    <Container
      maxWidth={false}
      sx={{ mb: 6, pt: 10 }}
      className={`tttCategory cat-${slug}`}
    >
      <ScrollToTop />
      <AppBar
        parent={``}
        parentTitle={parentTitle}
        languages={languages}
        showParent
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <Timeline
            target={<div className="timeline" style={{ height: '60vh' }} />}
            events={TLevents}
            title={TLtitle} // optional
            options={TLoptions} // optional
          /> */}
          <iframe
            src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=14-j3uAaccHwtMxmxeBt_HQCA8ykfBZe0gOQ34Zo2fHs&font=Default&lang=en&initial_zoom=2"
            width="100%"
            height="650"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            frameBorder="0"
            style={{ height: '80vh' }}
          ></iframe>
        </Grid>
      </Grid>
    </Container>
  )
})
