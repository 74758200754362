import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'

const Section = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(5),
  padding: theme.spacing(5, 3, 0),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 1, 0),
  },
  '&:last-child, &:first-of-type': {
    paddingBottom: theme.spacing(3),
  },
}))

export default Section
