import { Image } from 'mui-image'
import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import isTouchDevice from '../../helpers/isTouchDevice'

import Section from './Section'

const Hero = () => {
  return (
    <Section
      component="section"
      className="hero"
      style={{ position: 'relative' }}
    >
      <img
        className="tttHeroBk-image"
        src="https://ttt.photo/images/citeste/Atlantide_Valea-%C8%98e%C8%99ii-Credit-Adrian-Muntean.jpg"
        alt="ttt Hero Background"
        style={{
          backgroundPositionY: 'bottom',
        }}
      />
      <Container maxWidth={false}>
        <Box className="tttCats">
          <div className="tttCat tttCatCit">
            <div className="tttCatBk">
              <Image
                className="tttCatBk-image"
                src="https://ttt.photo/images/citeste/Bli%C8%9Bul%20cu%20endorfin%C4%83.jpg"
              />
              <div className="tttCatBk-blend"></div>
            </div>
            <div className="tttCatTitle">
              <Typography
                variant="h1"
                {...(isTouchDevice()
                  ? { component: Button, className: 'tttCatButton' }
                  : { component: Link, to: 'citeste' })}
              >
                Citește
              </Typography>
            </div>
            <div className="tttCatDesc">
              <div className="tttCatDesc-wrapper">
                <p>
                  <strong>articol</strong> | Andre de Dienes
                </p>
                <p>Secuiul care a descoperit-o pe Marilyn Monroe</p>
              </div>
            </div>
            {isTouchDevice() && (
              <Button
                variant="outlined"
                component={Link}
                to="citeste"
                className="tttCatButtonLink"
              >
                Toate articolele
              </Button>
            )}
          </div>
          <div className="tttCat tttCatPriv">
            <div className="tttCatBk">
              <Image
                className="tttCatBk-image"
                src="https://ttt.photo/images/priveste/RI_23_Hoover_Dam_FC_19351.jpg"
              />
              <div className="tttCatBk-blend"></div>
            </div>
            <div className="tttCatTitle">
              <Typography
                variant="h1"
                {...(isTouchDevice()
                  ? { component: Button, className: 'tttCatButton' }
                  : { component: Link, to: 'priveste' })}
              >
                Privește
              </Typography>
            </div>
            <div className="tttCatDesc">
              <div className="tttCatDesc-wrapper">
                <p>
                  <strong>video</strong> | Jordan Lloyd
                </p>
                <p>Trecutul, refăcut pixel cu pixel</p>
              </div>
            </div>
            {isTouchDevice() && (
              <Button
                variant="outlined"
                component={Link}
                to="priveste"
                className="tttCatButtonLink"
              >
                Toate articolele
              </Button>
            )}
          </div>
          <div className="tttCat tttCatAsc">
            <div className="tttCatBk">
              <Image
                className="tttCatBk-image"
                src="https://ttt.photo/images/asculta/Lomax.jpg"
              />
              <div className="tttCatBk-blend"></div>
            </div>
            <div className="tttCatTitle">
              <Typography
                variant="h1"
                {...(isTouchDevice()
                  ? { component: Button, className: 'tttCatButton' }
                  : { component: Link, to: 'asculta' })}
              >
                Ascultă
              </Typography>
            </div>
            <div className="tttCatDesc">
              <div className="tttCatDesc-wrapper">
                <p>
                  <strong>audio</strong> | Alan Lomax
                </p>
                <p>Înregistrările făcute la Densuș</p>
              </div>
            </div>
            {isTouchDevice() && (
              <Button
                variant="outlined"
                component={Link}
                to="asculta"
                className="tttCatButtonLink"
              >
                Toate articolele
              </Button>
            )}
          </div>
        </Box>
      </Container>
    </Section>
  )
}

export default Hero
