import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import Section from './Section'

const PageFooter = () => {
  return (
    <Section component="footer" className="footer">
      <Container maxWidth={false}>
        <Typography variant="caption" component="div" align="center">
          &copy; {new Date().getFullYear()} Transilvania Time Travel. Made with{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>{' '}
          by{' '}
          {/* <a
            href="https://webpunks.dev/"
            target="_blank"
            rel="noreferrer"
            title="webpunks.dev"
          >
            webpunks.dev
          </a> */}
          <a
            href="https://e-design.ro/"
            target="_blank"
            rel="noreferrer"
            title="e-deSign.ro"
          >
            e-deSign.ro
          </a>
        </Typography>
      </Container>
    </Section>
  )
}

export default PageFooter
