import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import Logo from './Logo'

const CustomLoadingComponent = ({ width = '100vw', height = '100vh' }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: width,
      height: height,
      backgroundColor: '#121212',
    }}
  >
    <Logo variant="loader" className="loader" color={'white'} />
    <CircularProgress size={128} thickness={5} color="secondary" />
  </Box>
)
export default CustomLoadingComponent
