import * as React from 'react'
import { Link } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import useScrollTrigger from '@mui/material/useScrollTrigger'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Masonry from 'react-masonry-css'
import MasonryCard from '../comps/content/MasonryCard'

import Section from '../comps/content/Section'

import Hero from '../comps/content/Hero'
import Featured from '../comps/content/Featured'
import Organizations from '../comps/content/Organizations'
import Footer from '../comps/content/PageFooter'

const MasonryBreakpoints = {
  default: 3,
  1900: 3,
  1400: 2,
  768: 1,
}

export default withGoogleSheets()(function HomePage(props) {
  const theme = useTheme()
  // let { path, url } = useMatch()
  const { db, window } = props
  let path = 'path',
    url = 'url'
  const { menu, categories, sponsors } = db

  const mqBelow = (breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint))

  const trigger =
    mqBelow('sm') &&
    useScrollTrigger({
      disableHysteresis: true,
      threshold: 174,
      target: window ? window() : undefined,
    })

  React.useEffect(() => {
    // Update the document title using the browser API
    document.title = `TTT :: Transylvania Time Travel`

    // RunIsotope()

    // HERO hovers
    // const validateHeroLinks = window.innerWidth > 900
    const parent = document.querySelector('.tttCats')
    const cats = document.querySelectorAll('.tttCat')
    const btns = document.querySelectorAll('.tttCatButton')

    // console.log(cats)
    for (var i = 0; i < cats.length; i++) {
      cats[i].onmouseenter = function () {
        parent.classList.add('dim')
        this.classList.add('active')
      }
      cats[i].onmouseleave = function () {
        parent.classList.remove('dim')
        this.classList.remove('active')
      }
    }

    if (trigger) {
      console.log('triggered')
      parent.classList.remove('dim')
      for (var i = 0; i < cats.length; i++) {
        cats[i].classList.remove('active')
      }
      for (var i = 0; i < btns.length; i++) {
        btns[i].blur()
      }
    }
  })

  // console.log(categories.filter((category) => category.slug === 'citeste')[0].content)
  // console.log(db)

  return (
    <>
      <Box className="mainContent">
        <Hero />
        <Container
          maxWidth="xl"
          style={{
            position: 'relative',
            // zIndex: 1
          }}
        >
          {/* <Featured /> */}

          {categories.map((category, index) => {
            if (JSON.parse(category.showOnHomePage.toLowerCase())) {
              return (
                <Section
                  component="section"
                  key={category.slug}
                  className={`tttCategory cat-${category.slug}`}
                >
                  <Typography
                    variant="h2"
                    component="h2"
                    gutterBottom
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {/* <Icon sx={{ color: '#392219' }}>{category.icon}</Icon> */}
                    {category.title}
                    <Button
                      // variant="contained"
                      size="small"
                      color="inherit"
                      component={Link}
                      to={`/${category.slug}`}
                    >
                      Toate
                    </Button>
                  </Typography>
                  <Masonry
                    breakpointCols={MasonryBreakpoints}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                  >
                    {db[category.slug].map(
                      (card, cardIndex) =>
                        !JSON.parse(card.featured.toLowerCase()) && (
                          <MasonryCard
                            shadow={0}
                            content={card}
                            to={`${category.slug}/${card.slug}`}
                            key={card.slug}
                            parent={category.slug}
                          />
                        ),
                    )}
                  </Masonry>
                </Section>
              )
            }
            return null
          })}
        </Container>

        {Array.isArray(sponsors) && sponsors.length > 0 && (
          <Organizations dataSource="sponsors" spacing={3} title="Sponsori" />
        )}

        <Footer />
      </Box>
    </>
  )
})
