import { useEffect } from 'react'
import {
  // BrowserRouter as Router,
  // Switch,
  // Routes,
  // Route,
  // Link,
  // useMatch,
  // useParams,
  useNavigate,
} from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { styled, useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import ScrollToTop from '../utils/ScrollToTop'
import AppBar from '../comps/navs/AppBar'
import Masonry from 'react-masonry-css'
import MasonryCard from '../comps/content/MasonryCard'

import DetailsPage from './Details'

export default withGoogleSheets()(function CategoryList({
  db,
  parentTitle,
  slug,
  title,
  type,
}) {
  // let {
  // url,
  // path,
  //   params: { category, eventId },
  // } = useMatch('/:category/:eventId')
  const navigate = useNavigate()
  const path = 'path',
    url = slug
  const theme = useTheme()
  const content = db[slug]

  const MasonryBreakpoints = {
    default: 4,
    1900: 4,
    1400: 3,
    768: 2,
    600: 1,
  }

  const languages = db.config[3]

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `TTT :: ${title}`
  })

  console.log(content)

  return (
    <Container
      maxWidth="xl"
      sx={{ marginBottom: 6, paddingTop: 10 }}
      className={`tttCategory cat-${slug}`}
    >
      <ScrollToTop />
      <AppBar parent={``} parentTitle={parentTitle} languages={languages} />
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        className="categoryTitle"
      >
        {title}
      </Typography>

      <Masonry
        breakpointCols={MasonryBreakpoints}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {content ? (
          // content[0].content &&
          // content[0].content.map((item, index) => (
          content.map((item, index) => (
            <MasonryCard
              shadow={0}
              content={item}
              to={item.slug}
              key={item.slug}
              parent={slug}
            />
          ))
        ) : (
          <div>No data!</div>
        )}
      </Masonry>
    </Container>
  )
})
