import * as React from 'react'
import { Link } from 'react-router-dom'

import { Image } from 'mui-image'

import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

import { useTheme } from '@mui/material/styles'

import moment from '../../helpers/moment'

export default function ActionAreaCard({ content, to, shadow, parent }) {
  const theme = useTheme()
  const cardSize = content.cardSize || 1

  // console.log(content, to)

  return (
    <Card
      elevation={shadow}
      sx={{
        width: '100%',
        // minWidth: `${cardSize * 320 + 30 * cardSize}px`,
        borderRadius: theme.spacing(0, 0, 0.5, 0.5),
      }}
    >
      <CardActionArea component={Link} to={to}>
        <CardMedia
          component="img"
          // height="140"
          image={`https://ttt.photo/images/${parent}/${
            content.hero || content.cover
          }`}
          alt={content.title}
          // sx={{
          //   minHeight: `${cardSize * 448 + 30 * (cardSize - 1)}px`,
          // }}
        />
        {/* <Image
          src={content.hero}
          alt={content.title}
          className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img"
        /> */}
        <CardContent
          sx={{
            // position: 'absolute',
            // bottom: 0,
            width: '100%',
            // backgroundColor:
            //   theme.mode === 'dark'
            //     ? 'rgba(194,119,102,.5)'
            //     : 'rgba(255,255,255,.55)',
            // backdropFilter: `opacity(65%) blur(3px) drop-shadow(4px 4px 4px ${
            //   theme.mode === 'dark'
            //     ? theme.palette.primary.main
            //     : theme.palette.background.default
            // })`,
            // borderTop: '4px solid',
            borderTop: '#000 4px solid !important',
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              textShadow:
                theme.mode === 'dark' &&
                `2px 2px 6px ${theme.palette.primary.main}`,
            }}
          >
            {content.title}
          </Typography>
          <Typography
            // gutterBottom
            variant="caption"
            component="div"
            className="author"
            sx={{
              paddingLeft: theme.spacing(1.5),
              borderLeft: `4px solid`,
            }}
          >
            {/* {content.author} */}
            {content.datePublished && (
              <>
                {/* , {mqBelow('sm') && <br />} */}
                {moment(content.datePublished).format('dddd, DD MMMM YYYY')}
              </>
            )}
          </Typography>
          {/* <Typography variant="body2" color="text.primary">
            {content.desc}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
