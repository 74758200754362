import * as React from 'react'

import { withGoogleSheets } from 'react-db-google-sheets'

import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
  useParams,
} from 'react-router-dom'

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  styled,
  useTheme,
} from '@mui/material/styles'
import { amber, deepOrange, grey } from '@mui/material/colors'

import useMediaQuery from '@mui/material/useMediaQuery'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Zoom from '@mui/material/Zoom'
import Fab from '@mui/material/Fab'

import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import moment from './helpers/moment'
import { products, data } from './data'

import HomePage from './pages/Home'
import CategoryPage from './pages/Category'
import DetailsPage from './pages/Details'
import TimelinePage from './pages/Timeline'
import LibraryPage from './pages/Library'
import GalleryPage from './pages/Gallery'
import NotFoundPage from './pages/NotFound'

import Drawer from './comps/navs/Drawer'
import MobileBar from './comps/navs/MobileBar'

import './styles.css'

const drawerWidth = 288
const mainColor = '#c27766'

const getDesignTokens = (mode) => ({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#10222d',
        },
      },
    },
  },
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: mainColor,
      }),
    },
    secondary: {
      // main: '#392219',
      main: '#10222d',
    },
    ...(mode === 'dark' && {
      background: {
        // default: '#111213',
        default: '#0c171e',
        paper: grey[900],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
  typography: {
    // fontFamily: 'OpenSans, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeightLight: 100,
    fontWeightRegular: 300,
    h1: {
      // fontFamily: 'OperanHairlineItalicTwoLines',
      // fontWeight: 300,
    },
    h2: {
      // fontFamily: 'OperanHairlineItalicTwoLines',
      // fontWeight: 300,
    },
    h3: {
      fontFamily: 'Domine, Times New Roman, Times, serif',
    },
    h4: {
      fontFamily: 'Domine, Times New Roman, Times, serif',
    },
    h5: {
      fontFamily: 'Domine, Times New Roman, Times, serif',
    },
    subtitle1: {
      fontFamily: 'Domine, Times New Roman, Times, serif',
    },
  },
})

let selectModeTheme = createTheme(getDesignTokens('dark'))
selectModeTheme = responsiveFontSizes(selectModeTheme)

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, check }) => ({
    flexGrow: 1,
    width: '100%',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    margin: 0,
    '& .mainContent': {
      // paddingRight: check('sm') ? 0 : 56,
    },
    // paddingRight: drawerWidth + 58,
    ...(open && {
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // paddingRight: 64,
    }),
  }),
)

function ScrollTop(props) {
  const { children, window, check } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: check('sm') ? 72 : 16,
          right: check('sm') ? 18 : 72,
        }}
      >
        {children}
      </Box>
    </Zoom>
  )
}
// ScrollTop.propTypes = {
//   children: PropTypes.element.isRequired,
// }

const App = (props) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [mode, setMode] = React.useState('dark')
  const mqBelow = (breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint))

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
        // document.querySelector('body').classList.add('bbDark')
      },
    }),
    [],
  )

  let selectModeTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            ...amber,
            ...(mode === 'dark' && {
              main: mainColor,
            }),
          },
          secondary: {
            // main: '#392219',
            main: '#10222d',
          },
          ...(mode === 'dark' && {
            background: {
              // default: '#111213',
              default: '#0c171e',
              paper: grey[900],
            },
          }),
          text: {
            ...(mode === 'light'
              ? {
                  primary: grey[900],
                  secondary: grey[800],
                }
              : {
                  primary: '#fff',
                  secondary: grey[500],
                }),
          },
        },
        typography: {
          fontSize: 16,
          fontWeightLight: 100,
          fontWeightRegular: 300,
          h1: {
            fontFamily: 'OperanHairlineRomanTwoLines',
            textTransform: 'uppercase',
          },
          h2: {
            fontFamily: 'OperanHairlineRomanTwoLines',
            textTransform: 'uppercase',
          },
          h3: {
            fontFamily: 'Domine, Times New Roman, Times, serif',
          },
          h4: {
            fontFamily: 'Domine, Times New Roman, Times, serif',
          },
          h5: {
            fontFamily: 'Domine, Times New Roman, Times, serif',
          },
          subtitle1: {
            fontFamily: 'Domine, Times New Roman, Times, serif',
          },
        },
        components: {
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true,
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: '#10222d',
              },
            },
          },
          MuiListItemText: {
            styleOverrides: {
              primary: {
                fontSize: '14px',
              },
              secondary: {
                fontSize: '13px',
              },
            },
          },
        },
      }),
    // createTheme(getDesignTokens('dark')),
    [mode],
  )

  // let selectModeTheme = createTheme(getDesignTokens(mode))
  selectModeTheme = responsiveFontSizes(selectModeTheme)

  const { db } = props

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const routes = db.menu.filter(function (el) {
    return el.type !== 'divider' && el.type !== 'home'
  })

  moment.locale('ro')

  const contentTypes = {
    home: HomePage,
    category: CategoryPage,
    details: DetailsPage,
    gallery: CategoryPage,
    timeline: TimelinePage,
  }

  const timelineData = [...db.citeste, ...db.asculta, ...db.priveste]

  // console.log(routes)

  return (
    <div className="App">
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={selectModeTheme}>
          <CssBaseline />

          <Box id="back-to-top-anchor" />

          <Box sx={{ display: 'flex', paddingBottom: mqBelow('sm') ? 7 : 0 }}>
            <BrowserRouter>
              <Main open={open} check={mqBelow}>
                <Routes>
                  <Route index element={<HomePage content="homePage" />} />
                  {routes.map((item, index) => {
                    return (
                      <React.Fragment key={item.slug}>
                        <Route
                          // exact
                          path={item.slug}
                          element={
                            item.type === 'timeline' ? (
                              <TimelinePage
                                title={item.title}
                                data={timelineData}
                              />
                            ) : item.type === 'gallery' ? (
                              <GalleryPage
                                title={item.title}
                                data={timelineData}
                                slug={item.slug}
                              />
                            ) : item.type === 'library' ? (
                              <LibraryPage
                                title={item.title}
                                data={timelineData}
                                slug={item.slug}
                              />
                            ) : (
                              <CategoryPage
                                title={item.title}
                                // content={data.categories.filter(
                                //   (category) => category.slug === item.slug,
                                // )}
                                slug={item.slug}
                                parentTitle={db.menu[0].title}
                                type={item.type}
                              />
                            )
                          }
                        />
                        <Route
                          path={`${item.slug}/:slug`}
                          element={
                            <DetailsPage
                              data={
                                db.categories.filter(
                                  (category) => category.slug === item.slug,
                                )[0]
                              }
                              parent={item.slug}
                              parentTitle={item.title}
                            />
                          }
                        />
                      </React.Fragment>
                    )
                  })}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Main>
              {mqBelow('sm') ? (
                <MobileBar menu={db.menu} languages={db.config} />
              ) : (
                <React.Fragment>
                  <Drawer
                    drawerWidth={drawerWidth}
                    open={open}
                    menu={db.menu}
                    languages={db.config}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    context={ColorModeContext}
                  />
                  <IconButton
                    color="default"
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                    className={`drawerIcon${!open ? ' iconDrawerClosed' : ''}`}
                    // style={{
                    //   right: open ? drawerWidth - 44 : 0,
                    // }}
                  >
                    {open ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </React.Fragment>
              )}
            </BrowserRouter>
          </Box>

          <ScrollTop {...props} check={mqBelow}>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  )
}

export default withGoogleSheets()(App)
