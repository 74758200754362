import React from 'react'
// import PropTypes from 'prop-types'

import { styled, useTheme } from '@mui/material/styles'
import MUICard from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

// const useStyles = makeStyles((theme) => ({
//   logoOrganisationCard: {},

//   cardMedia: {
//     margin: theme.spacing(2),
//     height: 0,
//     // paddingTop: '56.25%', // 16:9
//     paddingTop: '80%',
//     backgroundSize: 'contain',
//     '&>div': {
//       marginTop: `calc(-50% - ${theme.spacing(3.75)})`,
//       fontSize: theme.typography.h4.fontSize,
//       // [theme.breakpoints.only('md')]: {
//       //   marginTop: `calc(-50% - ${theme.spacing(3)})`,
//       //   fontSize: theme.typography.h5.fontSize,
//       // },
//     },
//   },
//   cardContent: {
//     '&, &:last-child': { padding: theme.spacing(1, 2) },
//   },

//   logoOrganisationTooltip: {
//     textAlign: 'center',
//   },
// }))
const Card = styled(MUICard)(({ theme, open }) => ({
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  // padding: theme.spacing(3),
  '& .MuiCardMedia-root': {
    textAlign: 'center',
  },
}))

const LogoOrganisation = ({ dataSource, imageFolder }) => {
  const theme = useTheme()
  // const classes = useStyles()
  // console.log(dataSource)
  return (
    <Card
      elevation={0}
      // className={classes.logoOrganisationCard}
      sx={{ cursor: 'default' }}
    >
      <Tooltip
        title={
          dataSource.organisationFull
            ? dataSource.organisationFull
            : dataSource.organisation
        }
        placement="bottom"
        // className={classes.logoOrganisationTooltip}
      >
        {dataSource.logo ? (
          <CardMedia
            image={`/images/${imageFolder}/${dataSource.logo}`}
            // className={classes.cardMedia}
          />
        ) : (
          <CardMedia
          // className={classes.cardMedia}
          >
            <Typography variant="button" component="div">
              {dataSource.organisation && dataSource.organisation}
            </Typography>
          </CardMedia>
        )}
      </Tooltip>
    </Card>
  )
}

LogoOrganisation.propTypes = {}

export default LogoOrganisation
