import React, { useEffect, useState } from 'react'
import {
  // BrowserRouter as Router,
  // Switch,
  // Routes,
  // Route,
  Link,
  // useMatch,
  // useParams,
  useNavigate,
} from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { styled, useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'

import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import MUIAppBar from '@mui/material/AppBar'

import Icon from '@mui/material/Icon'
import OriginalDateIcon from '@mui/icons-material/TodaySharp'
import CameraIcon from '@mui/icons-material/CameraAlt'
import TagIcon from '@mui/icons-material/LabelOutlined'
import CopyrightIcon from '@mui/icons-material/Copyright'
import SourceIcon from '@mui/icons-material/MonochromePhotos'

import ScrollToTop from '../utils/ScrollToTop'
import AppBar from '../comps/navs/AppBar'
import Masonry from 'react-masonry-css'
import GalleryCard from '../comps/content/GalleryCard'

import DetailsPage from './Details'
import zIndex from '@mui/material/styles/zIndex'

const catIcons = {
  citeste: 'auto_stories',
  priveste: 'videocam',
  asculta: 'headphones',
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function FullScreenDialog({ open, setOpen, item }) {
  const handleClose = () => {
    setOpen(false)
  }

  // console.log(item)

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ zIndex: 3000 }}
      >
        <MUIAppBar
          sx={{
            position: 'relative',
            padding: '0 !important',
            background: 'var(--color-dark-scrollbar)',
            // boxShadow: 'none',
          }}
        >
          <Toolbar style={{ backgroundColor: 'transparent' }}>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h5"
              component="div"
              style={{ fontSize: '1rem !important' }}
            >
              {item.Headline}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </MUIAppBar>
        <DialogContent
          style={{ padding: 0, backgroundColor: 'var(--color-dark-scrollbar)' }}
        >
          <Grid container spacing={2} style={{ height: '100%', marginTop: 0 }}>
            <Grid
              item
              xs={12}
              md={8}
              lg={9}
              xl={10}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={`https://ttt.photo/images/${item.category}/${item.Media}`}
                alt={item.Headline}
                style={{
                  width: '100%',
                  maxHeight: '90vh',
                  objectFit: 'scale-down',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              xl={2}
              style={{ backgroundColor: 'var(--color-dark)' }}
            >
              <List dense>
                <ListItem
                  button
                  component={Link}
                  to={`/${item.category}/${item.slug}`}
                >
                  <ListItemText
                    primary={item.Headline}
                    secondary="Titlu articol"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={item.Text}
                    secondary="Descriere"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <OriginalDateIcon />
                    </Avatar>
                  </ListItemAvatar>{' '}
                  <ListItemText
                    primary={item.Year}
                    secondary="An"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <CameraIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item['Media Credit']}
                    secondary="Credit"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <SourceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.Source}
                    secondary="Sursă"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <CopyrightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.Copyright}
                    secondary="Copyright"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <OriginalDateIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    // primary={item.Collection}
                    primary="-"
                    secondary="Colecție"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar sx={{ minWidth: 48 }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        color: 'text.secondary',
                      }}
                    >
                      <TagIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      item.tags &&
                      item.tags.split(',').map((tag) => (
                        <Chip
                          label={tag}
                          size="small"
                          color="default"
                          sx={{
                            borderRadius: 2,
                            margin: 0.25,
                            cursor: 'pointer',
                          }}
                          key={tag}
                          style={{ fontSize: '13px' }}
                        />
                        // <Chip label={item.tags} size="small" variant="outlined" />
                      ))
                    }
                    secondary="Tags"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default withGoogleSheets()(function CategoryList({
  db,
  parentTitle,
  slug,
  title,
}) {
  // let {
  // url,
  // path,
  //   params: { category, eventId },
  // } = useMatch('/:category/:eventId')
  const navigate = useNavigate()
  const path = 'path',
    url = slug
  const theme = useTheme()
  // const content = db[slug]
  const content = db.timelineTMP

  const MasonryBreakpoints = {
    default: 3,
    1900: 3,
    1400: 2,
    768: 1,
  }

  const languages = db.config[3]

  const [open, setOpen] = useState(false)
  const [currentItem, setItemContent] = useState(0)

  const handleClickOpen = (item) => {
    setOpen(true)
    setItemContent(item)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `TTT :: ${title}`
  })

  // console.log(parent)

  return (
    <Container
      maxWidth="xl"
      sx={{ marginBottom: 6, paddingTop: 10 }}
      className={`tttCategory cat-${slug}`}
    >
      <ScrollToTop />
      <AppBar
        parent={``}
        parentTitle={parentTitle}
        languages={languages}
        showParent
      />
      <Typography variant="h1" component="h1" gutterBottom>
        {title}
      </Typography>

      <Masonry
        breakpointCols={MasonryBreakpoints}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {content ? (
          // content[0].content &&
          // content[0].content.map((item, index) => (
          content.map((item, index) => (
            <GalleryCard
              shadow={0}
              content={item}
              to={item.slug}
              key={item.slug}
              openDetails={handleClickOpen}
              catIcon={catIcons[item.category]}
              parent={item.category}
            />
          ))
        ) : (
          <div>No data!</div>
        )}
      </Masonry>

      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        // handleClickOpen={handleClickOpen}
        item={currentItem}
      />
    </Container>
  )
})
